Ember.TEMPLATES["javascripts/discourse/connectors/category-custom-settings/custom-category-html"] = Ember.HTMLBars.template({"id":null,"block":"{\"symbols\":[],\"statements\":[[7,\"section\",true],[10,\"class\",\"field\"],[8],[0,\"\\n  \"],[7,\"label\",true],[8],[1,[28,\"i18n\",[\"category.layouts_custom_html\"],null],false],[9],[0,\"\\n  \"],[1,[28,\"text-field\",null,[[\"value\"],[[24,[\"category\",\"custom_fields\",\"layouts_custom_html\"]]]]],false],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}","meta":{"moduleName":"javascripts/discourse/connectors/category-custom-settings/custom-category-html"}});
define("discourse/plugins/layouts-custom-html/discourse/widgets/custom-html", ["exports", "@ember/runloop", "discourse/widgets/widget"], function (_exports, _runloop, _widget) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var layoutsError;
  var layouts;

  try {
    layouts = requirejs('discourse/plugins/discourse-layouts/discourse/lib/layouts');
  } catch (error) {
    layouts = {
      createLayoutsWidget: _widget.createWidget
    };
    console.error(error);
  }

  var _default = layouts.createLayoutsWidget('custom-html', {
    defaultState: function defaultState() {
      return {
        renderScheduled: false
      };
    },
    html: function html(attrs, state) {
      if (!state.renderScheduled) {
        var html = this.siteSettings.layouts_custom_html;
        var category = attrs.category;

        if (category && category.layouts_custom_html) {
          html = category.layouts_custom_html;
        }

        (0, _runloop.scheduleOnce)('afterRender', this, function () {
          $("div.layouts-custom-html").append("<div class='contents'>".concat(html, "</div>"));
        });
        state.renderScheduled = true;
      }

      return '';
    }
  });

  _exports.default = _default;
});

